// ** @jsx jsx *
import React from "react"
import { jsx, Button, Text } from "theme-ui"
import { Link } from "gatsby"
import CategoriesSnacks from "../CategoriesSnacks"
const CartEmpty = () => {
  return (
    <div sx={{ variant: "div.flex", justifyContent: "center", width: "100%" }}>
      <div sx={{ width: ["width1", "halfWidth2", "halfWidth3"] }}>
        <div
          sx={{
            variant: "div.flexColumn",
            boxShadow: "flat",
            backgroundColor: "backgroundSecondary",
            borderRadius: 2,
            padding: 2,
            paddingTop: 4,
            paddingBottom: 4,
          }}
        >
          <Text variant="title">Carrito vacío</Text>
          <br />
          <Text variant="title" sx={{ fontSize: 4 }}>
            ¯\_(ツ)_/¯
          </Text>
          <br />
          <Button as={Link} to="/">
            Comprar productos
          </Button>
        </div>
        <br />
        <br />
        <div
          sx={{
            variant: "div.flexColumn",
            boxShadow: "flat",
            backgroundColor: "backgroundSecondary",
            borderRadius: 2,
            padding: 2,
            paddingTop: 4,
            paddingBottom: 4,
          }}
        >
          <Text variant="primary">O busca en las categorías</Text>
          <br />
          <div sx={{ variant: "div.flex", flexWrap: "wrap" }}>
            <CategoriesSnacks
              style={{ width: "50%", backgroundColor: "red" }}
            />
          </div>
        </div>
      </div>
    </div>
  )
}

export default CartEmpty
